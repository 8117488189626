// 提取文件扩展名
export function file_ext_name(filename) {
    if (filename.indexOf('.') !== -1) {
        const arr = filename.split('.')
        const len = arr.length
        if (len > 0) {
            return arr[len - 1]
        } else {
            return ''
        }
    } else {
        return ''
    }
}
