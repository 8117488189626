<template>
  <div>
    <!--    <el-button round style="width: 100%; margin-top: 12px; margin-bottom: 12px;" type="warning"-->
    <!--               @click="onCreatePressed">-->
    <!--      新增-->
    <!--    </el-button>-->
    <el-alert v-if="group.status==1" type="warning" title="当前数据制作中，无法编辑..." :closable="false"/>
    <el-table
        v-loading="loading"
        style="width: 100%;height: 100%;margin-top: 12px;"
        empty-text="没有资源，点击“新增”按钮"
        :data="tableData.list"
    >
      <el-table-column fixed label="序号" width="100">
        <!--        <template #default="scope">-->
        <template #default="scope">
          <!--          <span>{{ scope.$index }}</span>-->
          <span>{{ this.tableData.total - scope.row.no + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="image" label="图片">
        <template #default="scope">
          <el-upload
              :id="scope.row.u_id"
              class="uploader"
              action="#"
              accept="image/*"
              v-loading.fullscreen.lock="uploading"
              :http-request="execUploadImage"
              :show-file-list="false"
              :before-upload="onImageUploadHandler"
              :disabled="!group.enable"
          >
            <el-popover
                v-if="scope.row.image"
                :width="200"
                trigger="hover"
            >
              <template #reference>
                {{ scope.row.file_name_i || '未命名图片' }}
              </template>
              <el-image width="200" :src="scope.row.preview_image" class="image"
                        @click="currentItemId=scope.row.u_id"/>
            </el-popover>
            <el-button v-else :disabled="!group.enable" size="small" type="primary"
                       @click="currentItemId=scope.row.u_id">点击上传
            </el-button>
          </el-upload>
        </template>
      </el-table-column>
      <el-table-column prop="video" label="视频">
        <template #default="scope">
          <el-upload
              class="uploader"
              action="#"
              accept="video/*"
              :http-request="execUploadVideo"
              :show-file-list="false"
              :before-upload="onVideoUploadHandler"
              :disabled="!group.enable"
          >
            <el-popover
                v-if="scope.row.video"
                placement="top-start"
                :width="200"
                trigger="hover"
            >
              <template #reference>
                {{ scope.row.file_name_v || '未命名视频' }}
              </template>
              <video width="200" :src="scope.row.preview_video" class="image" @click="currentItemId=scope.row.u_id"/>
            </el-popover>
            <el-button v-else :disabled="!group.enable" size="small" type="primary"
                       @click="currentItemId=scope.row.u_id">点击上传
            </el-button>
          </el-upload>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <el-button link
                     size="small"
                     :disabled="group.enable || scope.row.image.length <1 ||group.cover == scope.row.image"
                     @click="onSetCoverPressed(scope.row.image)">
            设为封面
          </el-button>
          <el-popconfirm
              confirm-button-text="确认"
              cancel-button-text="取消"
              icon="Info"
              icon-color="#626AEF"
              title="删除后不可恢复,确定要删除数据吗?"
              @confirm="onDeletePressed(scope.row)"
          >
            <template #reference>
              <el-button link
                         :disabled="!group.enable"
                         type="danger"
                         size="small">删除
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!--        分页器-->
    <div style="text-align: center; margin-top: 12px;">
      <el-pagination style="text-align: center;"
                     hide-on-single-page
                     layout="prev, pager, next"
                     :current-page="tableData.page"
                     :page-size="tableData.size"
                     :total="tableData.total"
                     @current-change="onPageChanged"
      />
    </div>

    <div v-if="group.enable">
      <el-button
          style="width: 100%; margin-top: 24px;" round
          type="warning" @click="onCreatePressed">
        新增
      </el-button>
      <el-button
          :disabled="tableData.total<1"
          style="width: 100%; margin-top: 12px;margin-left: 0px;" round
          type="success" @click="onEndUploadPressed">
        完成并通知制作
      </el-button>
    </div>

  </div>
</template>

<script>
import {createUpItem, deleteUpItem, getUploadItemList, updateUpItemInfo} from "@/api/api_item";
import {file_ext_name} from "@/util";
import {ElLoading} from "element-plus";
import {ElMessage} from 'element-plus'
import {endUpGroup, updateUpGroupCover} from "@/api/api_group";

export default {
  name: "UpItem",
  data() {
    return {
      group: {},
      ossClient: {},
      uploading: false,
      currentItemId: '',
      loading: false,
      tableData: {
        list: [],
        page: 1,
        size: 10,
        total: 0
      },
    }
  },
  mounted() {
    console.log('UpItem', 'mounted', this.group)
  },
  methods: {
    initGroup(item, client) {
      console.log('initGroup', item)
      this.group = item
      this.ossClient = client
      this.getUploadItemData()
    },
    onPageChanged(e) {
      console.log('onPageChanged', e)
      this.tableData.page = e
      this.getUploadItemData()
    },
    getLoading(text) {
      const loading = ElLoading.service({
        lock: true,
        text: text || '加载中...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      return loading
    },
    onCreatePressed() {
      console.log('onCreatePressed')
      const load = this.getLoading('创建中...')
      createUpItem({groupId: this.group.u_id}).then(() => {
        this.getUploadItemData()
      }).finally(() => {
        load.close()
      })
    },
    onEndUploadPressed() {
      console.log('onEndUploadPressed')
      const load = this.getLoading('正在通知制作...')
      endUpGroup({groupId: this.group.u_id}).then(() => {
        this.$emit('statusChanged')
      }).finally(() => {
        load.close()
      })
    },
    onDeletePressed(row) {
      console.log('onDeletePressed', row)
      const load = this.getLoading('删除中...')
      deleteUpItem({itemId: row.u_id}).then(() => {
        ElMessage.success('删除成功')
        this.getUploadItemData()
      }).finally(() => {
        load.close()
      })
    },
    onSetCoverPressed(url) {
      updateUpGroupCover(this.group.u_id, url).then(() => {
        this.group.cover = url
        ElMessage.success('设置成功')
        this.$emit('coverChanged', url)
      })
    },
    getUploadItemData() {
      this.loading = true
      getUploadItemList({
        groupId: this.group.u_id,
        page: this.tableData.page,
        size: this.tableData.size
      }).then((res) => {
        // console.log('getUploadItemList resp', res)
        this.tableData = res
        let index = ((this.tableData.page - 1) * this.tableData.size) + 1
        this.tableData.list.forEach((item => {
          item.no = index
          // console.log('index', index)
          if (item.image && item.image.length > 0) {
            item.preview_image = this.convertPreviewUrl(item.image)
          }
          if (item.video && item.video.length > 0) {
            item.preview_video = this.convertPreviewUrl(item.video)
          }
          index++
        }))
      }).finally(() => {
        this.loading = false
      })
    },
    // 签名预览地址
    convertPreviewUrl(url) {
      return this.ossClient.signatureUrl(url);
    },
    //上传图片文件
    execUploadImage({file}) {
      this.execUpload(file, false)
    },
    // 上传视频文件
    execUploadVideo({file}) {
      this.execUpload(file, true)
    },
    // 执行文件上传
    execUpload(file, isVideo) {
      // 提取文件扩展名
      const file_ext = file_ext_name(file.name)
      // 构造文件名
      const fileName = `${this.ossClient.options.url}/${this.group.u_id}/${this.currentItemId}.${file_ext}`
      // 上传参数构建
      const progress = async (p, checkpoint) => {
        this.currentCheckpoint = checkpoint
        const value = Math.floor(p * 100)
        console.log('上传进度:' + value)
        for (const index in this.uploadFiles) {
          const f = this.uploadFiles[index]
          if (f.file.uid === file.uid) {
            f.progress = value
          }
        }
      }
      const options = {
        progress,
        partSize: 100 * 1024
      }
      this.uploading = true
      this.ossClient.multipartUpload(fileName, file, options).then(({name, res}) => {
        if (res && res.status === 200) {
          const uploadName = file.name
          const path = name
          console.log(`OSS上传成功:`, uploadName, path)
          this.targetResAdd(isVideo, file, path)
        } else {
          console.log(`OSS上传失败:`)
          this.uploading = false
        }
      }).catch((err) => {
        console.log(`OSS上传失败:`, err)
        this.uploading = false
      })
    },
    targetResAdd(isVideo, file, path) {
      if (isVideo) {
        updateUpItemInfo({itemId: this.currentItemId, video: path}).finally(() => {
          this.uploading = false
          this.getUploadItemData()
        })
      } else {
        updateUpItemInfo({itemId: this.currentItemId, image: path}).finally(() => {
          this.uploading = false
          this.getUploadItemData()
        })
      }
    },
    onImageUploadHandler(file) {
      console.log('onImageUploadHandler', file)
      const {name, size} = file
      updateUpItemInfo({itemId: this.currentItemId, file_name_i: name, file_size_i: size})
      return true
    },
    onVideoUploadHandler(file) {
      console.log('onVideoUploadHandler', file)
      const {name, size} = file
      updateUpItemInfo({itemId: this.currentItemId, file_name_v: name, file_size_v: size})
      return true
    },
  }
}
</script>

<style scoped>
.el-pagination {
  justify-content: center;
}

</style>