import {createRouter, createWebHistory} from "vue-router";
import OrderAuth from "@/components/OrderAuth";
import OrderMain from "@/components/OrderMain";
import CreateUp from "@/components/CreateUp";
// import {info} from "@/api/api_order";
import {orderStore} from "@/store";


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            meta: {title: '认证'},
            path: '/',
            component: OrderAuth,
        },
        {
            meta: {title: '首页'},
            path: '/main',
            component: OrderMain
        },
        {
            meta: {title: '制作'},
            path: '/up',
            component: CreateUp
        }
    ]
})

// 使用路由守卫
router.beforeEach(async (to, from, next) => {
    document.title = 'UP！UP！'
    // console.log('router changed', from.path, '->', to.path)
    if (to.path === '/') {
        next()
    } else {
        const store = orderStore()
        try {
            // 获取一次当前订单信息
            await store.getOrderInfo()
            // 放行到下一个页面
            next()
        } catch (e) {
            // 发生错误的时候, 清空token
            store.removeToken()
            // 跳转到登录
            // next(`/login?redirect=${to.path}`)
            next('/')
        }

    }
})

export default router;