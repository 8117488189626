import request from "@/api/request";

// 获取当前订单下的所有AR列表 - 可不分页
export function getUploadItemList(data) {
    return request({
        url: '/up/list',
        method: 'post',
        data
    })
}

// 创建AR项
export function createUpItem(data) {
    return request({
        url: '/up/create',
        method: 'post',
        data
    })
}

// 编辑上传想
export function updateUpItemInfo(data) {
    return request({
        url: '/up/update',
        method: 'post',
        data
    })
}

// 删除上传项
export function deleteUpItem(data) {
    return request({
        url: '/up/remove',
        method: 'post',
        data
    })
}


// 获取上传数据的token
export function getUploadInfo(data) {
    return request({
        url: '/up/up',
        method: 'post',
        data
    })
}
