import {createApp} from 'vue'
import App from './App.vue'
// 引入ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 引入router
import router from "@/router";

import {registerPinia} from "@/store";

// 创建应用
const app = createApp(App)
// 注册插件
app.use(ElementPlus)
app.use(router)
registerPinia(app)
// 注册图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 挂载到dom
app.mount('#app')

