<template>
  <div>
    创建AR资源
  </div>
</template>

<script>
export default {
  name: "CreateUp"
}
</script>

<style scoped>
body {
  color: cadetblue;
}
</style>