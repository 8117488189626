<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {},
  created() {
    console.log('App', 'mounted')
  },
  mounted() {
    console.log('App', 'mounted')
  }
}
</script>

<style>
/* 消除默认的内外间距 */
html, body {
  margin: 0;
  padding: 0;
}

/* 定义应用区域的基础样式 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  /*margin-top: 60px;*/
}
</style>
