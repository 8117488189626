<template>
  <div class="common-layout">
    <el-container>
      <!--      头部区域-->
      <el-header class="header">
        <span class="product">UP！UP！</span>
        <div style="text-align: right;float: right">
          <el-tooltip
              class="box-item"
              effect="dark"
              content="退出制作"
          >
            <el-button type="danger" icon="Close" circle @click="onExitPressed"/>
          </el-tooltip>
        </div>
      </el-header>
      <el-main>
        <h3>
          订单编号:{{ order.orderId }}
          <el-tooltip
              class="box-item"
              effect="dark"
              content="点击按钮创建新的相册"
          >
            <el-button round :disabled="order.lock || list.length>order.limit" type="primary" icon="FolderAdd"
                       @click="onCreatePressed">
              新建AR数据
            </el-button>
          </el-tooltip>
          <div v-if="list.length>0" style="display: inline-block;margin-left: 10px;">
            <el-tooltip
                class="box-item"
                effect="dark"
                content="点击锁定上传,开始制作"
                v-if="!order.lock"
            >
              <el-button type="danger" icon="Lock" circle @click="onLockPressed"/>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="点击解除锁定,继续上传"
                v-else
            >
              <el-button type="success" icon="Unlock" circle @click="onLockPressed"/>
            </el-tooltip>
          </div>
        </h3>
        <!--        提示区域-->
        <div style="margin-top: 12px;">
          <el-alert type="success" show-icon :closable="false">
            <template v-slot:title>
              剩余 {{ order.limit - total }} 组数据制作权限, 如需增加请联系客服
            </template>
          </el-alert>
        </div>
        <!--        数据区域-->
        <div v-loading="loading">
          <div v-if="list.length>0" class="item-panel">
            <div v-for="item in list" :key="item" class="block" @click="onItemPressed(item)">
              <el-image style="width: 100px; height: 100px" :src="item.preview_cover" fit="contain">
                <template #placeholder>
                  <div class="image-slot" style="line-height: 80px;">加载中<span class="dot">...</span></div>
                </template>
                <template #error>
                  <div class="image-slot" style="width: 100px; height: 100px;line-height: 100px;">
                    <span v-if="item.cover.length>0">封面图片异常</span>
                    <span v-else>封面未设置</span>
                  </div>
                </template>
              </el-image>
              <span class="demonstration">{{ item.name }}</span>
            </div>
          </div>
          <div v-else class="item-panel">
            <el-empty description="没有上传过数据,点击上面的创建按钮开始上传吧"/>
          </div>
        </div>
        <!--        分页器-->
        <div style="text-align: center;">
          <el-pagination style="text-align: center;"
                         hide-on-single-page
                         layout="prev, pager, next"
                         :current-page="page"
                         :page-size="size"
                         :total="total"
                         @current-change="onPageChanged"
          />
        </div>
        <!--        右侧面板-->
        <el-drawer size="70%" v-model="showPanel" :with-header="false">
          <div class="drawer-panel">
            <div style="margin-top: 20px; margin-bottom:20px; float: right;">
              <el-popconfirm
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon="danger"
                  icon-color="#626AEF"
                  title="删除后不可恢复,确定要删除AR数据?"
                  @confirm="onDeletePressed"
              >
                <template #reference>
                  <el-button :disabled="!panelData.group.enable" round type="danger">删除AR数据</el-button>
                </template>
              </el-popconfirm>
            </div>
            <div class="name-panel" style="margin-top: 12px;margin-bottom: 24px;" :disabled="!panelData.group.enable">
              <span v-if="!isEditName" style="line-height: 24px;">
                {{ panelData.group.name }}
                <el-icon style="margin-left: 12px;" :size="24" @click="onEditNamePressed">
                  <Edit/>
                </el-icon>
              </span>
              <el-input v-else v-model="panelData.group.name">
                <template #append>
                  <el-button type="primary" @click="onSavePressed">保存</el-button>
                </template>
              </el-input>
            </div>
            <!--           上传项数据-->
            <UpItem ref="upItemPanel" @coverChanged="onCoverChanged" @statusChanged="onGroupStatusChanged"/>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </div>
</template>


<script>
import OSS from 'ali-oss'
import {getUploadInfo} from "@/api/api_item";
import {lockOrder} from "@/api/api_order";
import {ElLoading} from "element-plus";
import {ElMessage} from 'element-plus'
import {createUpGroup, getUpGroupList, removeUpGroup, updateUpGroupInfo} from "@/api/api_group";
import UpItem from "@/components/plugin/UpItem";

export default {
  name: "OrderMain",
  components: {UpItem},
  data() {
    return {
      // 是否显示编辑面板
      showPanel: false,
      isEditName: false,
      // 编辑面板数据
      panelData: {
        // 标题
        title: '',
        // 上传组信息
        group: {
          name: '未命名'
        },
        // 上传项列表
        list: [],
        // 上传项页码
        page: 1,
        // 上传项数量
        size: 10,
        // 上传项整体数量
        total: 0
      },
      // 订单信息
      order: {
        // 订单编号
        orderId: '',
        // 限制制作个数
        limit: 10,
        // 是否锁定
        lock: false,
      },
      // oss客户端
      ossClient: undefined,
      // 加载状态
      loading: true,
      page: 1,
      size: 10,
      total: 0,
      // 资源列表
      list: [],
      // 上传文件中
      uploading: false,
      uploadOpts: {},
    }
  },
  created() {
    console.log('OrderMain', 'created')
    window.vue = this
  },
  mounted() {
    console.log('OrderMain', 'mounted')
    // 从本地缓存中拿出订单信息
    this.order = this.$store.orderData
    // 从本地缓存中拿出订单编号
    this.order.orderId = this.$store.orderId
    // 初始化上传
    this.initUpload()
  },
  methods: {
    getLoading(text) {
      const loading = ElLoading.service({
        lock: true,
        text: text || '加载中...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      return loading
    },
    // 签名预览地址
    convertPreviewUrl(url) {
      return this.ossClient.signatureUrl(url);
    },
    onPageChanged(e) {
      console.log('onPageChanged', e)
      this.page = e
      this.getUploadData()
    },
    // 初始化上传逻辑
    initUpload() {
      const loading = this.getLoading()
      getUploadInfo().then(res => {
        // console.log('get upload info resp:', res)
        this.uploadOpts = res
      }).catch(err => {
        console.error('get upload info got error', err)
      }).finally(() => {
        // 初始化OSS客户端
        this.ossClient = OSS(this.uploadOpts)
        // 加载列表数据
        this.getUploadData()
        loading.close()
      })
    },
    // 获取数据组列表
    getUploadData() {
      getUpGroupList({page: this.page, size: this.size}).then(res => {
        // console.log('list data resp:', res)
        this.total = res.total
        this.list = res.list
        this.list.forEach((item => {
          if (item.cover && item.cover.length > 0) {
            item.preview_cover = this.convertPreviewUrl(item.cover)
          }
        }))
      }).catch(err => {
        console.error('list data got error', err)
      }).finally(() => {
        this.loading = false
      })
    },
    // 退出被点击
    onExitPressed() {
      console.log('onExitPressed')
      this.$store.removeToken()
      this.$router.replace('/')
    },
    // 创建被点击
    onCreatePressed() {
      console.log('onCreatePressed')
      if (this.order.limit <= this.total) {
        ElMessage.info('可上传数据组数量不足, 请联系客服增加')
        return
      }
      const loading = this.getLoading('创建中...')
      createUpGroup().then(() => {
        ElMessage.success('创建成功')
        this.getUploadData()
      }).finally(() => {
        loading.close()
      })
    },
    // 元素被点击
    onItemPressed(item) {
      console.log('onItemPressed', item)

      if (this.order.lock) {
        ElMessage.info('订单已锁定，请先点击解除锁定')
        return
      }

      this.showPanel = true
      this.isEditName = false
      this.panelData.group = item

      this.$nextTick(() => {
        // 初始化上传项数据
        this.$refs.upItemPanel.initGroup(item, this.ossClient)
      })

    },
    onLockPressed() {
      console.log('onLockPressed')
      const loading = this.getLoading('锁定中...')
      lockOrder(!this.order.lock).then(() => {
        this.order.lock = !this.order.lock
      }).finally(() => {
        loading.close()
      })
    },
    onEditNamePressed() {
      if (!this.panelData.group.enable) {
        ElMessage.warning('当前数据无法编辑')
        return
      }
      this.isEditName = true
    },
    onSavePressed() {
      console.log('onSavePressed')
      const loading = this.getLoading('保存中...')
      updateUpGroupInfo({
        groupId: this.panelData.group.u_id,
        name: this.panelData.group.name,
      }).then(res => {
        console.log('updateUpGroup', res)
        // this.showPanel = false
        ElMessage.success('修改成功')
        this.isEditName = false
        // 重新拉一下数据
        this.getUploadData()
      }).catch(err => {
        console.error('updateUp', err)
      }).finally(() => {
        loading.close()
      })
    },
    onDeletePressed() {
      console.log('onDeletePressed')
      const loading = this.getLoading('删除中...')
      removeUpGroup({groupId: this.panelData.group.u_id}).then(() => {
            // console.log('removeUpGroup', res)
            this.showPanel = false
            // 重新拉一下数据
            this.getUploadData()
          }
      ).catch(err => {
        console.error('Remove Up Group Got Error', err)
      }).finally(() => {
        loading.close()
      })
    },
    onCoverChanged() {
      this.getUploadData()
    },
    onGroupStatusChanged() {
      this.getUploadData()
      this.showPanel = false
    }
  }
}
</script>

<style scoped>

.header {
  background-color: #EF2D66;
  color: whitesmoke;
  height: var(--el-header-height);
  line-height: var(--el-header-height);
}

.header .product {
  font-size: 32px;
  margin-top: 12px;
}

.item-panel .block {
  /*padding: 30px 0;*/
  text-align: center;
  /*border-right: solid 1px var(--el-border-color);*/
  border: #EF2D66;
  border-width: 1px;
  border-style: dashed;
  margin: 12px;
  display: inline-block;
  width: 20%;
  box-sizing: border-box;
  vertical-align: top;
}

/*.item-panel .block:last-child {*/
/*  border-right: none;*/
/*}*/

.item-panel .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.el-pagination {
  justify-content: center;
}

.uploader .image {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style>
.uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>