import axios from 'axios'
// import { MessageBox, Message } from 'element-plus'
import {ElMessage} from 'element-plus'
import {orderStore} from '@/store'

// 让ajax携带cookie
// axios.defaults.withCredentials = true

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // baseURL: 'http://127.0.0.1:18082/api/fu/', // url = base url + request url
    baseURL: '/api/fu/', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10 * 60 * 1000 // request timeout -> 10分钟
})

// request interceptor
service.interceptors.request.use(
    config => {
        const store = orderStore()
        // do something before request is sent
        if (store.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = 'Bearer ' + store.token
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // 发生错误
        if (res.ret !== 200) {
            // 登录失败
            if (res.ret === 401) {
                // 登录失效提示
                ElMessage({
                    message: '登录信息已过期,请重新登录',
                    type: 'error',
                    duration: 3 * 1000
                })
                location.reload()
            } else {
                ElMessage({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 3 * 1000
                })
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res.obj
        }
    },
    error => {
        if (('' + error).indexOf('Network Error') >= 0) {
            const tips = '与服务器的连接发生问题，请检查网络'
            ElMessage({
                message: tips,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(tips)
        } else {
            if (error.response.status === 401) {
                orderStore.dispatch('user/resetToken').then(() => {
                    location.reload()
                })
            } else {
                ElMessage({
                    message: error.message,
                    type: 'error',
                    duration: 5 * 1000
                })
                return Promise.reject(error)
            }
        }
    }
)

export default service