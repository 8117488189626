<template>
  <div class="layout">
    <div ref="flower" id="flower-panel" class="not-loaded">
      <div class="night"></div>
      <div class="flowers">
        <div class="flower flower--1">
          <div class="flower__leafs flower__leafs--1">
            <div class="flower__leaf flower__leaf--1"></div>
            <div class="flower__leaf flower__leaf--2"></div>
            <div class="flower__leaf flower__leaf--3"></div>
            <div class="flower__leaf flower__leaf--4"></div>
            <div class="flower__white-circle"></div>

            <div class="flower__light flower__light--1"></div>
            <div class="flower__light flower__light--2"></div>
            <div class="flower__light flower__light--3"></div>
            <div class="flower__light flower__light--4"></div>
            <div class="flower__light flower__light--5"></div>
            <div class="flower__light flower__light--6"></div>
            <div class="flower__light flower__light--7"></div>
            <div class="flower__light flower__light--8"></div>

          </div>
          <div class="flower__line">
            <div class="flower__line__leaf flower__line__leaf--1"></div>
            <div class="flower__line__leaf flower__line__leaf--2"></div>
            <div class="flower__line__leaf flower__line__leaf--3"></div>
            <div class="flower__line__leaf flower__line__leaf--4"></div>
            <div class="flower__line__leaf flower__line__leaf--5"></div>
            <div class="flower__line__leaf flower__line__leaf--6"></div>
          </div>
        </div>

        <div class="flower flower--2">
          <div class="flower__leafs flower__leafs--2">
            <div class="flower__leaf flower__leaf--1"></div>
            <div class="flower__leaf flower__leaf--2"></div>
            <div class="flower__leaf flower__leaf--3"></div>
            <div class="flower__leaf flower__leaf--4"></div>
            <div class="flower__white-circle"></div>

            <div class="flower__light flower__light--1"></div>
            <div class="flower__light flower__light--2"></div>
            <div class="flower__light flower__light--3"></div>
            <div class="flower__light flower__light--4"></div>
            <div class="flower__light flower__light--5"></div>
            <div class="flower__light flower__light--6"></div>
            <div class="flower__light flower__light--7"></div>
            <div class="flower__light flower__light--8"></div>

          </div>
          <div class="flower__line">
            <div class="flower__line__leaf flower__line__leaf--1"></div>
            <div class="flower__line__leaf flower__line__leaf--2"></div>
            <div class="flower__line__leaf flower__line__leaf--3"></div>
            <div class="flower__line__leaf flower__line__leaf--4"></div>
          </div>
        </div>

        <div class="flower flower--3">
          <div class="flower__leafs flower__leafs--3">
            <div class="flower__leaf flower__leaf--1"></div>
            <div class="flower__leaf flower__leaf--2"></div>
            <div class="flower__leaf flower__leaf--3"></div>
            <div class="flower__leaf flower__leaf--4"></div>
            <div class="flower__white-circle"></div>

            <div class="flower__light flower__light--1"></div>
            <div class="flower__light flower__light--2"></div>
            <div class="flower__light flower__light--3"></div>
            <div class="flower__light flower__light--4"></div>
            <div class="flower__light flower__light--5"></div>
            <div class="flower__light flower__light--6"></div>
            <div class="flower__light flower__light--7"></div>
            <div class="flower__light flower__light--8"></div>

          </div>
          <div class="flower__line">
            <div class="flower__line__leaf flower__line__leaf--1"></div>
            <div class="flower__line__leaf flower__line__leaf--2"></div>
            <div class="flower__line__leaf flower__line__leaf--3"></div>
            <div class="flower__line__leaf flower__line__leaf--4"></div>
          </div>
        </div>

        <div class="grow-ans" style="--d:1.2s">
          <div class="flower__g-long">
            <div class="flower__g-long__top"></div>
            <div class="flower__g-long__bottom"></div>
          </div>
        </div>

        <div class="growing-grass">
          <div class="flower__grass flower__grass--1">
            <div class="flower__grass--top"></div>
            <div class="flower__grass--bottom"></div>
            <div class="flower__grass__leaf flower__grass__leaf--1"></div>
            <div class="flower__grass__leaf flower__grass__leaf--2"></div>
            <div class="flower__grass__leaf flower__grass__leaf--3"></div>
            <div class="flower__grass__leaf flower__grass__leaf--4"></div>
            <div class="flower__grass__leaf flower__grass__leaf--5"></div>
            <div class="flower__grass__leaf flower__grass__leaf--6"></div>
            <div class="flower__grass__leaf flower__grass__leaf--7"></div>
            <div class="flower__grass__leaf flower__grass__leaf--8"></div>
            <div class="flower__grass__overlay"></div>
          </div>
        </div>


        <div class="growing-grass">
          <div class="flower__grass flower__grass--2">
            <div class="flower__grass--top"></div>
            <div class="flower__grass--bottom"></div>
            <div class="flower__grass__leaf flower__grass__leaf--1"></div>
            <div class="flower__grass__leaf flower__grass__leaf--2"></div>
            <div class="flower__grass__leaf flower__grass__leaf--3"></div>
            <div class="flower__grass__leaf flower__grass__leaf--4"></div>
            <div class="flower__grass__leaf flower__grass__leaf--5"></div>
            <div class="flower__grass__leaf flower__grass__leaf--6"></div>
            <div class="flower__grass__leaf flower__grass__leaf--7"></div>
            <div class="flower__grass__leaf flower__grass__leaf--8"></div>
            <div class="flower__grass__overlay"></div>
          </div>
        </div>


        <div class="grow-ans" style="--d:2.4s">
          <div class="flower__g-right flower__g-right--1">
            <div class="leaf"></div>
          </div>
        </div>

        <div class="grow-ans" style="--d:2.8s">
          <div class="flower__g-right flower__g-right--2">
            <div class="leaf"></div>
          </div>
        </div>

        <div class="grow-ans" style="--d:2.8s">
          <div class="flower__g-front">
            <div class="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--1">
              <div class="flower__g-front__leaf"></div>
            </div>
            <div class="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--2">
              <div class="flower__g-front__leaf"></div>
            </div>
            <div class="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--3">
              <div class="flower__g-front__leaf"></div>
            </div>
            <div class="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--4">
              <div class="flower__g-front__leaf"></div>
            </div>
            <div class="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--5">
              <div class="flower__g-front__leaf"></div>
            </div>
            <div class="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--6">
              <div class="flower__g-front__leaf"></div>
            </div>
            <div class="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--7">
              <div class="flower__g-front__leaf"></div>
            </div>
            <div class="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--8">
              <div class="flower__g-front__leaf"></div>
            </div>
            <div class="flower__g-front__line"></div>
          </div>
        </div>

        <div class="grow-ans" style="--d:3.2s">
          <div class="flower__g-fr">
            <div class="leaf"></div>
            <div class="flower__g-fr__leaf flower__g-fr__leaf--1"></div>
            <div class="flower__g-fr__leaf flower__g-fr__leaf--2"></div>
            <div class="flower__g-fr__leaf flower__g-fr__leaf--3"></div>
            <div class="flower__g-fr__leaf flower__g-fr__leaf--4"></div>
            <div class="flower__g-fr__leaf flower__g-fr__leaf--5"></div>
            <div class="flower__g-fr__leaf flower__g-fr__leaf--6"></div>
            <div class="flower__g-fr__leaf flower__g-fr__leaf--7"></div>
            <div class="flower__g-fr__leaf flower__g-fr__leaf--8"></div>
          </div>
        </div>


        <div class="long-g long-g--0">
          <div class="grow-ans" style="--d:3s">
            <div class="leaf leaf--0"></div>
          </div>
          <div class="grow-ans" style="--d:2.2s">
            <div class="leaf leaf--1"></div>
          </div>
          <div class="grow-ans" style="--d:3.4s">
            <div class="leaf leaf--2"></div>
          </div>
          <div class="grow-ans" style="--d:3.6s">
            <div class="leaf leaf--3"></div>
          </div>
        </div>


        <div class="long-g long-g--1">
          <div class="grow-ans" style="--d:3.6s">
            <div class="leaf leaf--0"></div>
          </div>
          <div class="grow-ans" style="--d:3.8s">
            <div class="leaf leaf--1"></div>
          </div>
          <div class="grow-ans" style="--d:4s">
            <div class="leaf leaf--2"></div>
          </div>
          <div class="grow-ans" style="--d:4.2s">
            <div class="leaf leaf--3"></div>
          </div>
        </div>

        <div class="long-g long-g--2">
          <div class="grow-ans" style="--d:4s">
            <div class="leaf leaf--0"></div>
          </div>
          <div class="grow-ans" style="--d:4.2s">
            <div class="leaf leaf--1"></div>
          </div>
          <div class="grow-ans" style="--d:4.4s">
            <div class="leaf leaf--2"></div>
          </div>
          <div class="grow-ans" style="--d:4.6s">
            <div class="leaf leaf--3"></div>
          </div>
        </div>


        <div class="long-g long-g--3">
          <div class="grow-ans" style="--d:4s">
            <div class="leaf leaf--0"></div>
          </div>
          <div class="grow-ans" style="--d:4.2s">
            <div class="leaf leaf--1"></div>
          </div>
          <div class="grow-ans" style="--d:3s">
            <div class="leaf leaf--2"></div>
          </div>
          <div class="grow-ans" style="--d:3.6s">
            <div class="leaf leaf--3"></div>
          </div>
        </div>


        <div class="long-g long-g--4">
          <div class="grow-ans" style="--d:4s">
            <div class="leaf leaf--0"></div>
          </div>
          <div class="grow-ans" style="--d:4.2s">
            <div class="leaf leaf--1"></div>
          </div>
          <div class="grow-ans" style="--d:3s">
            <div class="leaf leaf--2"></div>
          </div>
          <div class="grow-ans" style="--d:3.6s">
            <div class="leaf leaf--3"></div>
          </div>
        </div>

        <div class="long-g long-g--5">
          <div class="grow-ans" style="--d:4s">
            <div class="leaf leaf--0"></div>
          </div>
          <div class="grow-ans" style="--d:4.2s">
            <div class="leaf leaf--1"></div>
          </div>
          <div class="grow-ans" style="--d:3s">
            <div class="leaf leaf--2"></div>
          </div>
          <div class="grow-ans" style="--d:3.6s">
            <div class="leaf leaf--3"></div>
          </div>
        </div>

        <div class="long-g long-g--6">
          <div class="grow-ans" style="--d:4.2s">
            <div class="leaf leaf--0"></div>
          </div>
          <div class="grow-ans" style="--d:4.4s">
            <div class="leaf leaf--1"></div>
          </div>
          <div class="grow-ans" style="--d:4.6s">
            <div class="leaf leaf--2"></div>
          </div>
          <div class="grow-ans" style="--d:4.8s">
            <div class="leaf leaf--3"></div>
          </div>
        </div>

        <div class="long-g long-g--7">
          <div class="grow-ans" style="--d:3s">
            <div class="leaf leaf--0"></div>
          </div>
          <div class="grow-ans" style="--d:3.2s">
            <div class="leaf leaf--1"></div>
          </div>
          <div class="grow-ans" style="--d:3.5s">
            <div class="leaf leaf--2"></div>
          </div>
          <div class="grow-ans" style="--d:3.6s">
            <div class="leaf leaf--3"></div>
          </div>
        </div>

      </div>
    </div>
    <el-container>
      <el-aside class="left-panel">Up!Up!采集系统</el-aside>
      <el-main class="main-panel">
        <div class="auth">
          <el-form ref="form" :model="auth" :rules="rules" label-width="100px">
            <el-form-item label="订单编号" prop="orderId">
              <el-input
                  placeholder="请输入订单号"
                  prefix-icon="PriceTag"
                  autofocus
                  :disabled="loading"
                  v-model="auth.orderId">
              </el-input>
            </el-form-item>
            <el-form-item label="收件电话" prop="contact">
              <el-input
                  placeholder="请输入收件人电话"
                  prefix-icon="Cellphone"
                  :disabled="loading"
                  v-model="auth.contact">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="Upload" @click="onAuthPressed" :loading="loading">开始制作</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-main>
    </el-container>
  </div>
</template>


<script>

import {auth} from "@/api/api_order";
import {parseValueCaesar} from "@/util/caesar";
import {ElMessage} from 'element-plus'

export default {
  name: "OrderAuth",
  data() {
    return {
      loading: false,
      auth: {
        orderId: '',
        contact: '',
        // orderId: 'tb123456789',
        // contact: '18766668888',
      },
      rules: {
        'orderId': [
          {required: true, message: '必须输入订单号', trigger: 'blur'},
          {min: 8, max: 50, message: '订单号长度不符合', trigger: 'blur'},
        ],
        'contact': [
          {required: true, message: '必须输入收件人电话', trigger: 'blur'},
          {min: 3, max: 15, message: '收件人电话长度不符合', trigger: 'blur'},
        ]
      },
    }
  },
  created() {
    console.log('OrderAuth', 'created')
    const conf = this.$route?.query?.conf
    if (conf && conf.length > 1) {
      console.log('发现自配置参数')
      try {
        const raw = parseValueCaesar(conf)
        const data = JSON.parse(raw)
        this.auth.orderId = data.orderId
        this.auth.contact = data.contact
      } catch {
        ElMessage.warning('解析配置失败，请手动输入订单编号')
      }
    }
  },
  mounted() {
    console.log('OrderAuth', 'mounted')
    this.$refs.flower.removeAttribute("class")
    // 当前存在之前存储的订单编号
    if (this.$store?.orderId) {
      // 订单编号满足规则
      if (this.$store.orderId.length > 0) {
        // 自动填充订单编号
        this.auth.orderId = this.$store.orderId
      }
    }
  },
  methods: {
    onAuthPressed() {
      this.$refs.form.validate((isValid) => {
        // console.log('validate', isValid, invalidFields)
        if (isValid) {
          this.onAuth()
        }
      })
    },
    onAuth() {
      this.loading = true
      auth(this.auth).then(res => {
        // console.log('auth resp', res)
        const token = res.token
        this.$store.setOrderId(this.auth.orderId)
        this.$store.setToken(token)
        this.$router.replace('/main')
      }).catch(err => {
        console.error('auth err', err)
      }).finally(() => {
        // console.log('auth end')
        this.loading = false
      })
    }
  }
}
</script>

<style src="../assets/flower.css"></style>

<style scoped>
.layout {
  width: 100vw;
  height: 100vh;
}

#flower-panel {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
}

.left-panel {
  width: 150px;
  color: whitesmoke;
  font-size: 72px;
  font-family: "黑体", "Arial Narrow";
  background-color: #EF2D66;
  height: 100vh;
  writing-mode: vertical-rl;
  padding: 24px;
  opacity: 0.5;
}

.main-panel {
  color: whitesmoke;
  /*background-color: cadetblue;*/
  height: 100vh;
}

.auth {
  margin-top: 100px;
  border-radius: 24px;
  color: white;
  background-color: aliceblue;
  padding: 24px;
}

</style>